var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.visible,
        width: "400px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        close: _vm.onClose
      }
    },
    [
      _c(
        "c-box",
        { attrs: { padding: "10px 40px" } },
        [
          _c(
            "c-box",
            {
              attrs: {
                flex: "row",
                align: "center",
                padding: "10px",
                justify: "flex-start"
              }
            },
            [
              _c("c-box", { attrs: { width: "100px" } }, [
                _vm._v("下载模板：")
              ]),
              _c(
                "el-link",
                { attrs: { type: "primary" }, on: { click: _vm.download } },
                [_vm._v("下载")]
              )
            ],
            1
          ),
          _c(
            "c-box",
            {
              attrs: {
                flex: "row",
                align: "center",
                padding: "10px",
                justify: "flex-start"
              }
            },
            [
              _c("c-box", { attrs: { width: "100px" } }, [
                _vm._v("导入数据：")
              ]),
              _c(
                "CheckFiles",
                {
                  attrs: { fileTypes: ["xls", "xlsx"] },
                  on: { error: _vm.onerror, submit: _vm.onsubmit }
                },
                [
                  _c("el-link", { attrs: { type: "success" } }, [
                    _vm._v("导入")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }